@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-qr-code {
  background: linear-gradient(180deg, #eceff5, #fcfcfc, #f0f2f5, #ffffff);
  box-shadow: 0 10pt 30pt #a1bce266;
  transition: 1s all ease-in;
}

.countdown-wrapper {
  background-color: #f6f9fc;
  border: 1px solid #dfe7ef;
}

.tbl-transaction th,
.tbl-transaction td {
  padding-bottom: 0.5rem;
}

.tbl-transaction td {
  text-align: left;
}

.tbl-transaction th {
  text-align: right;
}

.qr-active {
  filter: grayscale(0) blur(0);
}

.qr-expired {
  filter: grayscale(1) blur(10px);
}
